<template>
  <div class="vue-tempalte">
    <form>
      <h3>{{ "Recharge credits" }}</h3>
      <br>

      <div class="form-group">
        <label>{{ "amount" }}</label>
        <input type="number" v-model="amount" @change="rechargeBank" class="form-control form-control-lg"/>
      </div>

      <div class="form-group" v-if="amount > 0">
        <label>{{ "price" }}</label>
        <input type="text" v-model="recharge.price" class="form-control form-control-lg" readonly/>
      </div>

      <div class="form-group" v-if="amount > 0">
        <label>{{ "account" }}</label>
        <input type="text" v-model="recharge.account" class="form-control form-control-lg" readonly/>
      </div>

      <div class="form-group" v-if="amount > 0">
        <label>{{ "VS" }}</label>
        <input type="text" v-model="recharge.variable_symbol" class="form-control form-control-lg" readonly/>
      </div>

      <div class="form-group" v-if="amount > 0">
        <label>{{ "CS" }}</label>
        <input type="text" v-model="recharge.constant_symbol" class="form-control form-control-lg" readonly/>
      </div>

      <img v-if="amount > 0" :src="recharge.qr_url" width="250" height="250">

      <input type="button" v-if="amount > 0" @click="rechargePaypal" :value=" 'or via Paypal' " class="btn btn-primar">

    </form>
  </div>
</template>


<script>
import {socialvue} from '@/config/pluginInit'
import api from "@/api/api";

export default {
  name: 'RechargeCreditsPage',
  mounted() {
    socialvue.index()
  },
  data() {
    return {
      amount: 0,
      recharge: null
    }
  },
  created() {
    // reset login status
  },
  methods: {
    rechargeBank() {
      api
          .post('wallet/rechargeBank', {
            token: this.token,
            price: this.amount.toString()
          })
          .then((response) => {
            if (response.data.status) {
              this.recharge = response.data
            }

          })
    },
    rechargePaypal() {
      api
          .post('wallet/rechargePaypal', {
            token: this.token,
            price: this.amount.toString()
          })
          .then((response) => {
            if (response.data.status) {
              location.href = response.data.recharge.url
            }

          })
    }
  },
  computed: {
    token() {
      return this.$store.getters["Setting/token"];
    }
  }
};
</script>

<style scoped>
input[type="button"] {
  background-color: #ef476f;
  color: white;
  width: 100%;
  height: 50px;
  margin-top: 20px;
}
</style>
